body {
  margin: 0;
  font-family: Poppins,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.justify-center {
  justify-content: center !important;
}


/* .container {
  max-width: 960px!important;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
} */

@media (max-width: 768px) {
  .hero {
    padding-top: 0px; 
  }

  .bottle-img {
    width:40% !important;
  }
}

.text-center {
  text-align: center!important;
}

.cta-button-header {
    margin: 10px auto;
    font-size: 1rem;
    font-weight: 900;
    padding: 10px 45px;
    box-shadow: 1px 1px 3px 0 #000;
}

.header-item > a{
  margin-right:40px;
  line-height:50px;
  font-weight: bold;
  color: white;
}

h1 {
  font-family: 'Poppins', sans-serif;
}

.color-black {
  color:black !important;
}

.vsl-heading-h3 {
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  text-shadow: 1px 3px 4px rgb(0 0 0 / 50%);
  color: #fff;
  padding: 0px !important;
}

.header-links{
  margin-right: -13%;
}

.font20, .accordion-body > p {
  font-size: 20px;
}
.accordion-header > button {
  font-size: 25px;
}

.li-space > li {
  margin: 10px 0;
}

.blue-background3 {
  background-color: #1a2270;
  background-image: url('https://alpilean.com/assets/images/mountain.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.addToCart {
  font-weight: bold;
}

.blog-heading {
  font-weight: 700;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 40%);
  padding: 0px !important;
}

.vsl-heading {
  font-size: 2.8vh;
  line-height: 3.2vh;
  font-weight: 700;
  text-shadow: 1px 3px 4px rgb(0 0 0 / 50%);
  color: #fff
}

@media (min-width: 576px) {
  .vsl-heading {
      font-size:50px;
      line-height: 55px;
      padding: 25px
  }
}

.paypal-img {
  border: #041e73 2px solid;
}


.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #379884;
  padding: 10px;
  color: white;
}

.header-button {
  background-color: white;
  color: darkgreen;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.header-button:hover {
  background-color: lightgreen;
  color: white;
}

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }
}
